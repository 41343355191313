import React, { FC, useEffect, useState } from 'react';
import WindowComponent from '../WindowComponent/WindowComponent';
import styles from './PageLayout.module.css';
import BarChartIcon from '@mui/icons-material/BarChart';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { UserAuthority } from '../CheckAuthority/UserAuthority';

interface PageLayoutProps {}

const PageLayout: FC<PageLayoutProps> = () => {

  let navigate = useNavigate();
  const [username, setUsername] = useState("");

  
  function LogoutUser(){
    localStorage.removeItem('userAuthority');
    navigate("/login");
  }

  useEffect(() => {
    let user:UserAuthority = JSON.parse(localStorage.getItem('userAuthority') || "{}");

    if(user.user.firstName !== undefined && user.user.lastName !== undefined){
      setUsername(user.user.firstName + ' ' + user.user.lastName);
    }
  },[]);
  
  return(
    <div className={styles.PageLayout}>
      <div style={{ marginLeft: '20px'}}>
        <WindowComponent width={'360px'} height={'60px'}>
          <div style={{justifyContent: 'center', display: 'flex'}}>
            <div className={styles.TitleComponent} style={{marginTop: '10px'}}>
              <BarChartIcon style={{fontSize: '38px', marginRight: '10px'}}/>
              <div>Brainix Reporting</div>
              <BarChartIcon style={{fontSize: '38px', marginLeft: '10px', transform: 'scaleX(-1)'}}/>
            </div>
          </div>
        </WindowComponent>
      </div>
      <div style={{ marginRight: '20px'}}>
        <WindowComponent width={'470px'} height={'60px'}>
          <div className={styles.LoggedInComponent}>
            <div className={styles.LoggedInComponentTextBox}>
              <div className={styles.LoggedInComponentText}>Logged in as:</div>
              <div className={styles.LoggedInComponentText}>{username}</div>
            </div>
            <Button
              onClick={() => LogoutUser()}
              startIcon={<LogoutIcon />}
              variant="outlined" 
              style={{
                color: '#af0a0a', 
                borderColor: '#af0a0a', 
                background: '#ff00000d', 
                marginTop: '4px'
              }}>
              Log out
            </Button>
          </div>
        </WindowComponent>
      </div>
    </div>
  );
}
export default PageLayout;
