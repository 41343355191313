import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import React, { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import AllReportsComponent from '../AllReportsComponent/AllReportsComponent';
import PageLayout from '../PageLayout/PageLayout';
import SchoolTable from '../Tables/SchoolTable/SchoolTable';
import SubjectGroupsTable from '../Tables/SubjectGroupsTable/SubjectGroupsTable';
import WindowComponent from '../WindowComponent/WindowComponent';
import styles from './HomePage.module.css';

interface HomePageProps {}

const steps = ['Select schools', 'Select subject groups', 'Select report type'];

const HomePage: FC<HomePageProps> = () => {

  const [activeStep, setActiveStep] = React.useState(0);
  const [selctedReportType, setSelctedReportType] = React.useState<number>(1);
  const [disableNextSchool, setDisableNextSchool] = React.useState(true);
  const [disableNextSubjectGroups, setDisableNextSujectGroups] = React.useState(true);
  const [disableNextAllReports, setDisableNextAllReports] = React.useState(true);
  const lessonInsightsReportType = 3;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isButtonDisabled = (step:number):boolean => {
    switch (step) {
      case 0:
        return disableNextSchool;
      case 1:
        return disableNextSubjectGroups;
      case 2:
        return disableNextAllReports;
      default: return false;
    }
  }

  const renderStep = (step:number) => {
    switch(step)
    {
      case 0:
        return (<div style={{height: '490px'}}><SchoolTable setIsNextButtonDisabled={setDisableNextSchool}/></div>);
      case 1:
        return (<div style={{height: '490px'}}><SubjectGroupsTable setIsNextButtonDisabled={setDisableNextSujectGroups}/></div>);
      case 2:
        return (<div style={{height: '490px'}}><AllReportsComponent setSelctedReportType={setSelctedReportType} setIsNextButtonDisabled={setDisableNextAllReports}/></div>);
            
    }
  }

  return (
    <div className={styles.HomePage}>
      <PageLayout/>

      <div style={{marginTop: '10vh'}}>
        <WindowComponent width={'1200px'} height={'600px'}>

          <div style={{height: '12px'}}/>

          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} sx={{margin: '0px 15px'}}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div style={{borderBottom: '1px #0000005c solid', marginTop: '14px'}}/>
            {activeStep === steps.length ? (              
              //First three report types point to getReports fetch             
              selctedReportType <= lessonInsightsReportType ? (
                <React.Fragment>
                <Navigate to={`/getReports/${selctedReportType}`} />
              </React.Fragment>
              ): (
                selctedReportType < 7 ?(
                   <React.Fragment>
                <Navigate to={`/getReportsForCorrectness/${selctedReportType}`} />
              </React.Fragment>
                ):
                
                <React.Fragment>
                <Navigate to={`/GetReportForUser`} />
              </React.Fragment>                  
            )
              
            ) : (
              <React.Fragment>
                {renderStep(activeStep)}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="secondary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleNext} color="primary" disabled={isButtonDisabled(activeStep)}>
                    {activeStep === steps.length - 1 ? 'Generate report' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </WindowComponent>
      </div>
    </div>
  );
}

export default HomePage;
