import React, { FC, useEffect } from "react";
import styles from "./SubjectGroupsTable.module.css";
import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import { SubjectGroup } from "./SubjectGroup";
import { UserAuthority } from "../../CheckAuthority/UserAuthority";

interface SubjectGroupsTableProps {
  setIsNextButtonDisabled: any;
}

const SubjectGroupsTable: FC<SubjectGroupsTableProps> = (props) => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "grade", headerName: "Grade", width: 100 },
    { field: "name", headerName: "Subject group name", width: 940 },
  ];

  const [selectedSubjectGroups, setSelectedSubjectGroups] =
    React.useState<GridSelectionModel>([]);
  const [hasAppStarted, setHasAppStarted] = React.useState(false);
  const [subjectGroups, setSubjectGroups] = React.useState<SubjectGroup[]>([]);

  function fetchSubjectGroups() {
    let authorityUser: UserAuthority = JSON.parse(
      localStorage.getItem("userAuthority") || "{}"
    );
    var isAuthorized = false;
    var reqString: string = "";

    if (localStorage.getItem("selectedSchools") !== null) {
      var arrayOfStr: string[] = JSON.parse(
        localStorage.getItem("selectedSchools") || "{}"
      );
      arrayOfStr.forEach((element) => {
        reqString += "schoolIds=" + element + "&";
      });
      reqString = reqString.slice(0, -1);
    } else return;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorityUser.accessToken}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_API_REPORTING_SERVICE}/Schools/ReturnSubjectGroups?${reqString}`,
      requestOptions
    )
      .then((response) => {
        if (response.status !== 401) {
          isAuthorized = true;
          return response.json();
        } else {
          isAuthorized = false;
        }
      })
      .then((data) => {
        if (isAuthorized) {
          if (data.returnSubjectGroups !== undefined)
            setSubjectGroups(data.returnSubjectGroups);
          if (localStorage.getItem("selectedSubjectGroups") !== null) {
            var preSelectedSubjectGroups: number[] = [];
            var arrayOfStr: string[] = JSON.parse(
              localStorage.getItem("selectedSubjectGroups") || "{}"
            );
            arrayOfStr.forEach((element) => {
              const index: number = parseInt(element);
              data.returnSubjectGroups.forEach((group: { id: number }) => {
                if (group.id === index) preSelectedSubjectGroups.push(index);
              });
            });
            setSelectedSubjectGroups(preSelectedSubjectGroups);
          }
          setHasAppStarted(true);
        }
      });
  }

  useEffect(() => {
    setSelectedSubjectGroups([]);
    fetchSubjectGroups();
  }, []);

  useEffect(() => {
    if (hasAppStarted) {
      if (selectedSubjectGroups.length === 0)
        props.setIsNextButtonDisabled(true);
      else props.setIsNextButtonDisabled(false);
      localStorage.setItem(
        "selectedSubjectGroups",
        JSON.stringify(selectedSubjectGroups)
      );
    }
  }, [selectedSubjectGroups]);

  return (
    <div className={styles.SubjectGroupsTable}>
      {hasAppStarted ? (
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={subjectGroups}
            columns={columns}
            pageSize={7}
            rowsPerPageOptions={[7]}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectedSubjectGroups(newSelectionModel);
            }}
            selectionModel={selectedSubjectGroups}
          />
        </div>
      ) : (
        <>
          <div style={{ height: "180px" }}></div>
          <div className={styles.TitleLoadingComponent}>
            <div>Fetching subject groups...</div>
          </div>
          <CircularProgress
            style={{ color: "#e9d5ff", width: "50px", height: "50px" }}
          />
        </>
      )}
    </div>
  );
};

export default SubjectGroupsTable;
