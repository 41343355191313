import React, { Component, FC, useEffect } from "react";
import { Navigate } from "react-router-dom";
import styles from "./CheckAuthority.module.css";
import { UserAuthority } from "./UserAuthority";
import { useLocation } from "react-router-dom";

interface CheckAuthorityProps {
  component: JSX.Element;
}

const CheckAuthority: FC<CheckAuthorityProps> = (props) => {
  const location = useLocation();
  var isAuthorized: boolean = false;
  let authorityUser: UserAuthority = JSON.parse(
    localStorage.getItem("userAuthority") || "{}"
  );
  let timeToday = new Date().getTime();
  let expirationDate = new Date(authorityUser.expirationDate).getTime();
  if (authorityUser.accessToken === undefined) {
    return <Navigate to={`/login?q=${location.pathname}`} />;
  }
  if (expirationDate > timeToday) {
    //Try authenticate
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorityUser.accessToken}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_API_USER_SERVICE}/user/${authorityUser.user.userId}`,
      requestOptions
    ).then((response) => {
      if (response.status !== 401) {
        isAuthorized = true;
      } else {
        isAuthorized = false;
        localStorage.removeItem("userAuthority");
        return <Navigate to={`/login?q=${location.pathname}`} />;
      }
    });
  } else {
    //Try refresh token

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refreshToken: authorityUser.refreshToken,
        userId: authorityUser.user.userId,
      }),
    };
    fetch(
      `${process.env.REACT_APP_API_USER_SERVICE}/Token/refresh`,
      requestOptions
    )
      .then((response) => {
        if (response.status !== 401) {
          isAuthorized = true;
          return response.json();
        } else {
          isAuthorized = false;
          localStorage.removeItem("userAuthority");
          return <Navigate to={`/login?q=${location.pathname}`} />;
        }
      })
      .then((data) => {
        if (isAuthorized) {
          authorityUser.accessToken = data.accessToken;
          authorityUser.refreshToken = data.refreshToken;
          authorityUser.expireSeconds = data.expireSeconds;
          authorityUser.expirationDate = new Date();
          authorityUser.expirationDate.setMinutes(
            authorityUser.expirationDate.getMinutes() +
              authorityUser.expireSeconds / 60
          );
          localStorage.setItem("userAuthority", JSON.stringify(authorityUser));
        }
      });
  }
  return props.component;
};

export default CheckAuthority;
