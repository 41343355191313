import React, { FC, useEffect } from "react";
import PageLayout from "../../PageLayout/PageLayout";
import WindowComponent from "../../WindowComponent/WindowComponent";
import styles from "./ReportsTable.module.css";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Button from "@mui/material/Button";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { UserAuthority } from "../../CheckAuthority/UserAuthority";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Report } from "./Report";
import FileSaver from "file-saver";
import { utils, write } from "xlsx";

interface ReportsTableCorrectnessProps {}
const columnsForCorrectnessReport: GridColDef[] = [
  { field: "lessonId", headerName: "Lesson Index", width: 110 },
  { field: "lessonName", headerName: "Lesson Name", width: 250 },
  {
    field: "studentsWithProgress",
    headerName: "Users With Progress",
    width: 180,
  },
  { field: "avgCorrectness", headerName: "Correctness", width: 150 },
  {
    field: "studentsCompleted",
    headerName: "Users Wuth Complited Progress",
    width: 220,
  },
  {
    field: "studentsWithHighCorrectness",
    headerName: "Users with more than 75% correctness",
    width: 250,
  },
  { field: "studentsTotal", headerName: "Total Studnets", width: 120 },
];

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const fileName = "Brainix Report";

const ReportsTableCorrectness: FC<ReportsTableCorrectnessProps> = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isThereAnError, setIsThereAnError] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState<number>(0);
  const [reportData, setReportData] = React.useState<Report[]>([]);
  let navigate = useNavigate();
  const { reportId } = useParams();

  function renderError(errorType: number) {
    switch (errorType) {
      case 1:
        return (
          <>
            <div className={styles.LoadingComponent}>
              <SearchOffIcon
                style={{
                  color: "#e9d5ff",
                  width: "75px",
                  height: "75px",
                  marginBottom: "-20px",
                }}
              />
              <div className={styles.TitleLoadingComponent}>
                <div>Error: Missing information</div>
              </div>
              <Button
                variant="outlined"
                color="error"
                style={{ marginTop: "15px" }}
                onClick={() => navigate("/home")}
              >
                Return to main page
              </Button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className={styles.LoadingComponent}>
              <AutoGraphIcon
                style={{
                  color: "#e9d5ff",
                  width: "75px",
                  height: "75px",
                  marginBottom: "-20px",
                }}
              />
              <div className={styles.TitleLoadingComponent}>
                <div>Error: Invalid report type</div>
              </div>
              <Button
                variant="outlined"
                color="error"
                style={{ marginTop: "15px" }}
                onClick={() => navigate("/home")}
              >
                Return to main page
              </Button>
            </div>
          </>
        );
    }
  }

  function exportToExcel() {
    const ws = utils.json_to_sheet(reportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  function fetchReport() {
    let authorityUser: UserAuthority = JSON.parse(
      localStorage.getItem("userAuthority") || "{}"
    );
    var isAuthorized = false;
    var reqString: string = "";

    var arrayOfStr: string[] = JSON.parse(
      localStorage.getItem("selectedSubjectGroups") || "{}"
    );
    arrayOfStr.forEach((element) => {
      reqString += "subjectGroups=" + element + "&";
    });
    reqString = reqString.slice(0, -1);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorityUser.accessToken}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_API_REPORTING_SERVICE}/Reports/GetReportsForCorrectness?reportType=${reportId}&${reqString}`,
      requestOptions
    )
      .then((response) => {
        if (response.status !== 401) {
          if (response.status === 200) {
            isAuthorized = true;
            return response.json();
          } else {
            setIsLoading(false);
            setIsThereAnError(true);
            setErrorCode(3);
          }
        } else {
          setIsLoading(false);
          setIsThereAnError(true);
          setErrorCode(2);
        }
      })
      .then((data) => {
        if (isAuthorized) {
          setReportData(data.reportsList);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsThereAnError(true);
        setErrorCode(3);
      });
  }

  useEffect(() => {
    //If true then everything is fine
    if (localStorage.getItem("selectedSubjectGroups") !== null) {
      fetchReport();
    } else {
      setIsLoading(false);
      setIsThereAnError(true);
      setErrorCode(1);
    }
  }, []);

  return (
    <div className={styles.ReportsTable}>
      <PageLayout />
      <div style={{ marginTop: "10vh" }} />
      <WindowComponent width={"1200px"} height={"600px"}>
        {isLoading ? (
          <div className={styles.LoadingComponent}>
            <CircularProgress
              style={{ color: "#e9d5ff", width: "50px", height: "50px" }}
            />
            <div className={styles.TitleLoadingComponent}>
              <div>Generating report...</div>
            </div>
          </div>
        ) : isThereAnError ? (
          renderError(errorCode)
        ) : (
          <>
            <div style={{ height: "90%", width: "100%" }}>
              <DataGrid
                rows={reportData}
                columns={columnsForCorrectnessReport}
                pageSize={8}
                rowsPerPageOptions={[8]}
                style={{
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                }}
              />
            </div>
            <div className={styles.ButtonsReport}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => navigate("/home")}
              >
                Generate another report
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => exportToExcel()}
              >
                Export to excel
              </Button>
            </div>
          </>
        )}
      </WindowComponent>
    </div>
  );
};

export default ReportsTableCorrectness;
