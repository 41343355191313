import React, { FC, useEffect } from "react";
import PageLayout from "../../PageLayout/PageLayout";
import WindowComponent from "../../WindowComponent/WindowComponent";
import styles from "./ReportsTable.module.css";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "@mui/material/Button";
import { UserAuthority } from "../../CheckAuthority/UserAuthority";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Report } from "./Report";
import FileSaver from "file-saver";
import { utils, write } from "xlsx";
import RenderError from "../../Handlers/ErrorHandler";

interface ReportsTableProps {}
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 30 },
  { field: "lessonId", headerName: "Lesson ID", width: 90 },
  { field: "typeId", headerName: "Type ID", width: 70 },
  { field: "lessonName", headerName: "Lesson Name", width: 250 },
  { field: "caption", headerName: "Caption", width: 200 },
  { field: "subCaption", headerName: "Sub Caption", width: 200 },
  { field: "text", headerName: "Text", width: 200 },
  {
    field: "averageCorrectness",
    headerName: "Average Correctness",
    width: 160,
  },
  { field: "subjectGroup", headerName: "Subject Group", width: 160 },
];
const columnsForUserReport: GridColDef[] = [
  { field: "id", headerName: "ID", width: 30 },
  { field: "schoolName", headerName: "School Name", width: 250 },
  { field: "subjectGroupName", headerName: "SubjectGroup Name", width: 250 },
  { field: "teachers", headerName: "Teachers", width: 100 },
  { field: "students", headerName: "Students", width: 100 },
  { field: "managers", headerName: "Managers", width: 100 },
  { field: "parents", headerName: "Parents", width: 80 },
];

columnsForUserReport.forEach((c) => {
  c.sortable = false;
});

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const fileName = "Brainix Report";

const ReportsTable: FC<ReportsTableProps> = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isThereAnError, setIsThereAnError] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState<number>(0);
  const [reportData, setReportData] = React.useState<Report[]>([]);
  let navigate = useNavigate();
  const { reportId } = useParams();

  function exportToExcel() {
    const ws = utils.json_to_sheet(reportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  function fetchReport() {
    let authorityUser: UserAuthority = JSON.parse(
      localStorage.getItem("userAuthority") || "{}"
    );
    var isAuthorized = false;
    var reqString: string = "";
    var reqSchoolsString: string = "";

    var arrayOfStr: string[] = JSON.parse(
      localStorage.getItem("selectedSubjectGroups") || "{}"
    );
    arrayOfStr.forEach((element) => {
      reqString += "subjectGroups=" + element + "&";
    });
    reqString = reqString.slice(0, -1);

    arrayOfStr = JSON.parse(localStorage.getItem("selectedSchools") || "{}");
    arrayOfStr.forEach((element) => {
      reqSchoolsString += "schools=" + element + "&";
    });
    reqSchoolsString = reqSchoolsString.slice(0, -1);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorityUser.accessToken}`,
      },
    };
    Number(reportId) < 4
      ? fetch(
          `${process.env.REACT_APP_API_REPORTING_SERVICE}/Reports/GetReports?reportType=${reportId}&${reqString}`,
          requestOptions
        )
          .then((response) => {
            if (response.status !== 401) {
              if (response.status === 200) {
                isAuthorized = true;
                return response.json();
              } else {
                setIsLoading(false);
                setIsThereAnError(true);
                setErrorCode(3);
              }
            } else {
              setIsLoading(false);
              setIsThereAnError(true);
              setErrorCode(2);
            }
          })
          .then((data) => {
            if (isAuthorized) {
              setReportData(data.reportsList);
              // if(data.returnSubjectGroups !== undefined) setSubjectGroups(data.returnSubjectGroups);
              // if(localStorage.getItem('selectedSubjectGroups') !== null){
              //   var preSelectedSubjectGroups:number[] = [];
              //   var arrayOfStr:string[] = JSON.parse(localStorage.getItem('selectedSubjectGroups') || "{}");
              //   arrayOfStr.forEach(element => {
              //     const index:number = parseInt(element);
              //     data.returnSubjectGroups.forEach((group: { id: number; }) => {
              //       if(group.id === index) preSelectedSubjectGroups.push(index);
              //     });
              //   });
              //   setSelectedSubjectGroups(preSelectedSubjectGroups);
              // }
              setIsLoading(false);
            }
          })
          .catch(() => {
            setIsLoading(false);
            setIsThereAnError(true);
            setErrorCode(3);
          })
      : fetch(
          `${process.env.REACT_APP_API_REPORTING_SERVICE}/Reports/GetReportForUser?${reqString}&${reqSchoolsString}`,
          requestOptions
        )
          .then((response) => {
            if (response.status !== 401) {
              if (response.status === 200) {
                isAuthorized = true;
                return response.json();
              } else {
                setIsLoading(false);
                setIsThereAnError(true);
                setErrorCode(3);
              }
            } else {
              setIsLoading(false);
              setIsThereAnError(true);
              setErrorCode(2);
            }
          })
          .then((data) => {
            if (isAuthorized) {
              setReportData(data.reportsList);
              setIsLoading(false);
            }
          })
          .catch(() => {
            setIsLoading(false);
            setIsThereAnError(true);
            setErrorCode(3);
          });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    //If true then everything is fine
    if (localStorage.getItem("selectedSubjectGroups") !== null) {
      fetchReport();
    } else {
      setIsLoading(false);
      setIsThereAnError(true);
      setErrorCode(1);
    }
  }, []);

  return (
    <div className={styles.ReportsTable}>
      <PageLayout />
      <div style={{ marginTop: "10vh" }} />
      <WindowComponent width={"1200px"} height={"600px"}>
        {isLoading ? (
          <div className={styles.LoadingComponent}>
            <CircularProgress
              style={{ color: "#e9d5ff", width: "50px", height: "50px" }}
            />
            <div className={styles.TitleLoadingComponent}>
              <div>Generating report...</div>
            </div>
          </div>
        ) : isThereAnError ? (
          <RenderError errorType={errorCode} />
        ) : (
          <>
            <div style={{ height: "90%", width: "100%" }}>
              {Number(reportId) < 4 ? (
                <DataGrid
                  rows={reportData}
                  columns={columns}
                  pageSize={8}
                  rowsPerPageOptions={[8]}
                  style={{
                    borderTopLeftRadius: "16px",
                    borderTopRightRadius: "16px",
                  }}
                />
              ) : (
                <DataGrid
                  rows={reportData}
                  columns={columnsForUserReport}
                  pageSize={8}
                  rowsPerPageOptions={[8]}
                  style={{
                    borderTopLeftRadius: "16px",
                    borderTopRightRadius: "16px",
                  }}
                />
              )}
            </div>
            <div className={styles.ButtonsReport}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => navigate("/home")}
              >
                Generate another report
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => exportToExcel()}
              >
                Export to excel
              </Button>
            </div>
          </>
        )}
      </WindowComponent>
    </div>
  );
};

export default ReportsTable;
