import React, { FC } from 'react';
import styles from './WindowComponent.module.css';

interface WindowComponentProps {
  height: any,
  width: any,
  children: any
}

const WindowComponent: FC<WindowComponentProps> = (props) => (
  <div className={styles.WindowComponent} style={{ width: props.width, height: props.height}}>
    {props.children}
  </div>
);

export default WindowComponent;
