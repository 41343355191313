import React from 'react';
import './App.css';
import LoginComponent from './Components/LoginComponent/LoginComponent';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CheckAuthority from './Components/CheckAuthority/CheckAuthority';
import HomePage from './Components/HomePage/HomePage';
import ReportsTable from './Components/Tables/ReportsTable/ReportsTable';
import ReportsTableCorrectness from './Components/Tables/ReportsTable/ReportsTableCorrectness';

function App() {
  return (
    <div className="Background">
      <div className="CenterComponents">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={'/home'}/>}/>
          <Route path="/login" element={<LoginComponent />}/>
          <Route path="/home" element={<CheckAuthority component={<HomePage/>}/>}/>
          <Route path="/getReports/:reportId" element={<CheckAuthority component={<ReportsTable/>}/>}/>
          <Route path="/getReportForUser" element={<CheckAuthority component={<ReportsTable/>}/>}/>
          <Route path="/getReportsForCorrectness/:reportId" element={<CheckAuthority component={<ReportsTableCorrectness/>}/>}/>
        </Routes>
      </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
