import React, { FC, useEffect } from "react";
import styles from "./SchoolTable.module.css";
import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { School } from "./School";
import { UserAuthority } from "../../CheckAuthority/UserAuthority";
import CircularProgress from "@mui/material/CircularProgress";

interface SchoolTableProps {
  setIsNextButtonDisabled: any;
}

const SchoolTable: FC<SchoolTableProps> = (props) => {
  const [selectedSchools, setSelectedSchools] =
    React.useState<GridSelectionModel>([]);
  const [hasAppStarted, setHasAppStarted] = React.useState(false);
  const [schools, setSchools] = React.useState<School[]>([]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "School name", width: 1040 },
  ];

  function fetchSchools() {
    let authorityUser: UserAuthority = JSON.parse(
      localStorage.getItem("userAuthority") || "{}"
    );
    var isAuthorized = false;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorityUser.accessToken}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_API_REPORTING_SERVICE}/Schools/ReturnSchools`,
      requestOptions
    )
      .then((response) => {
        if (response.status !== 401) {
          isAuthorized = true;
          return response.json();
        } else {
          isAuthorized = false;
        }
      })
      .then((data) => {
        if (isAuthorized) {
          if (data.returnList !== undefined) setSchools(data.returnList);
          if (localStorage.getItem("selectedSchools") !== null) {
            var preSelectedSchools: number[] = [];
            var arrayOfStr: string[] = JSON.parse(
              localStorage.getItem("selectedSchools") || "{}"
            );
            arrayOfStr.forEach((element) => {
              const index: number = parseInt(element);
              data.returnList.forEach((group: { id: number }) => {
                if (group.id === index) preSelectedSchools.push(index);
              });
            });
            setSelectedSchools(preSelectedSchools);
          }
          setHasAppStarted(true);
        }
      });
  }

  useEffect(() => {
    setSelectedSchools([]);
    fetchSchools();
  }, []);

  useEffect(() => {
    if (hasAppStarted) {
      if (selectedSchools.length === 0) props.setIsNextButtonDisabled(true);
      else props.setIsNextButtonDisabled(false);
      localStorage.setItem("selectedSchools", JSON.stringify(selectedSchools));
    }
  }, [selectedSchools]);

  return (
    <div className={styles.SchoolTable}>
      {hasAppStarted ? (
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={schools}
            columns={columns}
            pageSize={7}
            rowsPerPageOptions={[7]}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectedSchools(newSelectionModel);
            }}
            selectionModel={selectedSchools}
          />
        </div>
      ) : (
        <>
          <div style={{ height: "180px" }}></div>
          <div className={styles.TitleLoadingComponent}>
            <div>Fetching schools...</div>
          </div>
          <CircularProgress
            style={{ color: "#e9d5ff", width: "50px", height: "50px" }}
          />
        </>
      )}
    </div>
  );
};
export default SchoolTable;
