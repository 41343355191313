import TextField from "@mui/material/TextField";
import React, { FC, useEffect, useState } from "react";
import WindowComponent from "../WindowComponent/WindowComponent";
import styles from "./LoginComponent.module.css";
import BarChartIcon from "@mui/icons-material/BarChart";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { UserAuthority } from "../CheckAuthority/UserAuthority";
import { Navigate, useNavigate } from "react-router-dom";
import RenderError from "../Handlers/ErrorHandler";

interface LoginComponentProps {}

const LoginComponent: FC<LoginComponentProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMailVaild, setIsMailValid] = useState(true);
  const [mailInvalidText, setMailInvalidText] = useState("");
  const [isPasswordVaild, setIsPasswordValid] = useState(true);
  const [passwordInvalidText, setPasswordInvalidText] = useState("");
  const [usernameValue, setUsernameValue] = useState("");
  let navigate = useNavigate();

  const onLoginFormSubmit = (e: any) => {
    e.preventDefault();
    let username = e.target.username.value;
    let password = e.target.password.value;

    //Start of validation script
    var emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(username)) {
      setIsMailValid(true);
      setMailInvalidText("");
    } else {
      setIsMailValid(false);
      setMailInvalidText("Invalid email!");
    }

    if (password != "") {
      setIsPasswordValid(true);
      setPasswordInvalidText("");
    } else {
      setIsPasswordValid(false);
      setPasswordInvalidText("Invalid password!");
    }

    if (password == "" || !emailRegex.test(username)) return;
    //End of validation script

    setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userNameOrEmail: username,
        password: password,
        project: 2,
      }),
    };
    fetch(
      `${process.env.REACT_APP_API_USER_SERVICE}/user/login`,
      requestOptions
    )
      .then((response) => {
        setIsLoading(false);
        if (response.status !== 401) {
          if (response.status === 200) {
            return response.json();
          }
        } else {
          setIsPasswordValid(false);
          setIsMailValid(false);
          setPasswordInvalidText("Invalid credentials!");
        }
      })
      .then((data) => {
        if (isPasswordVaild) {
          let authority: UserAuthority = data;
          authority.expirationDate = new Date();
          authority.expirationDate.setMinutes(
            authority.expirationDate.getMinutes() + authority.expireSeconds / 60
          );
          localStorage.setItem("userAuthority", JSON.stringify(authority));

          if (window.location.search) {
            const params = new URLSearchParams(window.location.search);
            let url = params.get("q");
            if (url) {
              navigate(`${url}`);
            } else {
              navigate("/home");
            }
          } else {
            navigate("/home");
          }
        }
      });
  };

  return (
    <WindowComponent width={"450px"} height={"auto"}>
      {!isLoading ? (
        <div className={styles.LoginComponent}>
          <div style={{ height: "15px" }}></div>
          <div className={styles.TitleLoginComponent}>
            <BarChartIcon style={{ fontSize: "38px", marginRight: "10px" }} />
            <div>Brainix Reporting</div>
            <BarChartIcon
              style={{
                fontSize: "38px",
                marginLeft: "10px",
                transform: "scaleX(-1)",
              }}
            />
          </div>
          <form onSubmit={onLoginFormSubmit}>
            <TextField
              value={usernameValue}
              onChange={(e) => setUsernameValue(e.target.value)}
              error={!isMailVaild}
              helperText={mailInvalidText}
              id="username-field"
              label="Username"
              variant="outlined"
              name="username"
              fullWidth
              style={{ marginTop: "20px" }}
            />
            <TextField
              error={!isPasswordVaild}
              helperText={passwordInvalidText}
              id="password-field"
              label="Password"
              variant="outlined"
              name="password"
              type="password"
              fullWidth
              style={{ marginTop: "20px" }}
            />
            <div className={styles.ButtonGroup}>
              <Button variant="outlined" disabled>
                Forgot password
              </Button>
              <Button variant="outlined" type="submit">
                Login
              </Button>
            </div>
          </form>
          <div style={{ height: "25px" }}></div>
        </div>
      ) : (
        <>
          <div style={{ height: "15px" }}></div>
          <div className={styles.TitleLoginComponent}>
            <div>Authenticating user...</div>
          </div>
          <CircularProgress
            style={{ color: "#e9d5ff", width: "50px", height: "50px" }}
          />
          <div style={{ height: "15px" }}></div>
        </>
      )}
    </WindowComponent>
  );
};
export default LoginComponent;
