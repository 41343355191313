import SearchOffIcon from "@mui/icons-material/SearchOff";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import styles from "./ErrorHandler.module.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IRenderErrorProps {
  errorType: number;
}

const RenderError: React.FC<IRenderErrorProps> = ({ errorType }) => {
  let navigate = useNavigate();
  switch (errorType) {
    case 1:
      return (
        <>
          <div className={styles.LoadingComponent} key={errorType}>
            <SearchOffIcon
              style={{
                color: "#e9d5ff",
                width: "75px",
                height: "75px",
                marginBottom: "-20px",
              }}
            />
            <div className={styles.TitleLoadingComponent}>
              <div>Error: Missing information</div>
            </div>
            <Button
              variant="outlined"
              color="error"
              style={{ marginTop: "15px" }}
              onClick={() => navigate("/home")}
            >
              Return to main page
            </Button>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <div className={styles.LoadingComponent} key={errorType}>
            <AutoGraphIcon
              style={{
                color: "#e9d5ff",
                width: "75px",
                height: "75px",
                marginBottom: "-20px",
              }}
            />
            <div className={styles.TitleLoadingComponent}>
              <div>Error: Invalid report type</div>
            </div>
            <Button
              variant="outlined"
              color="error"
              style={{ marginTop: "15px" }}
              onClick={() => navigate("/home")}
            >
              Return to main page
            </Button>
          </div>
        </>
      );
    default:
      return (
        <>
          <div className={styles.LoadingComponent} key={errorType}>
            <SearchOffIcon
              style={{
                color: "#e9d5ff",
                width: "75px",
                height: "75px",
                marginBottom: "-20px",
              }}
            />
            <div className={styles.TitleLoadingComponent}>
              <div>Something went wrong!</div>
            </div>
            <Button
              variant="outlined"
              color="error"
              style={{ marginTop: "15px" }}
              onClick={() => navigate("/home")}
            >
              Return to main page
            </Button>
          </div>
        </>
      );
  }
};
export default RenderError;
