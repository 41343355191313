import { Radio } from "@mui/material";
import React, { FC } from "react";
import styles from "./SingleReportType.module.css";

interface SingleReportTypeProps {
  id: number;
  description: string;
  handleChange: any;
  selectedValue: any;
  hasGroup?: any;
}

const SingleReportType: FC<SingleReportTypeProps> = (props) => (
  <div>
    <div></div>
    <div
      className={styles.SingleReportType}
      style={
        props.hasGroup
          ? { width: "1000px", marginLeft: "50px" }
          : { width: "1150px", height: "60px", margin: "30px 15px 0px 10px" }
      }
    >
      <Radio
        checked={props.selectedValue === props.id.toString()}
        onChange={props.handleChange}
        value={props.id.toString()}
        name="radio-buttons"
        color="secondary"
        style={{ height: "40px" }}
      />
      <div className={styles.TitleComponent}>{props.description}</div>
    </div>
    <div />
  </div>
);

export default SingleReportType;
