import CircularProgress from "@mui/material/CircularProgress";
import React, { FC, useEffect } from "react";
import { UserAuthority } from "../CheckAuthority/UserAuthority";
import RenderError from "../Handlers/ErrorHandler";
import SingleReportType from "../SingleReportType/SingleReportType";
import styles from "./AllReportsComponent.module.css";
import { ReportType } from "./ReportType";

interface AllReportsComponentProps {
  setIsNextButtonDisabled: any;
  setSelctedReportType: any;
}

const AllReportsComponent: FC<AllReportsComponentProps> = (props) => {
  const [selectedValue, setSelectedValue] = React.useState("1");
  const [reports, setReports] = React.useState<ReportType[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isThereAnError, setIsThereAnError] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState<number>(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    props.setSelctedReportType(parseInt(event.target.value));
  };

  function fetchReportTypes() {
    let authorityUser: UserAuthority = JSON.parse(
      localStorage.getItem("userAuthority") || "{}"
    );
    var isAuthorized = false;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorityUser.accessToken}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_API_REPORTING_SERVICE}/Reports/GetReportTypes`,
      requestOptions
    )
      .then((response) => {
        if (response.status !== 401) {
          if (response.status === 200) {
            isAuthorized = true;
            return response.json();
          } else {
            setIsLoading(false);
            setIsThereAnError(true);
            setErrorCode(3);
          }
        } else {
          setIsLoading(false);
          setIsThereAnError(true);
          setErrorCode(2);
        }
      })
      .then((data) => {
        if (isAuthorized) {
          setReports(data.availableReports);
          props.setIsNextButtonDisabled(false);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsThereAnError(true);
        setErrorCode(3);
      });
  }

  useEffect(() => {
    fetchReportTypes();
  }, []);
  return (
    <>
      {!isLoading ? (
        isThereAnError ? (
          <RenderError errorType={errorCode} />
        ) : (
          <div className={styles.AllReportsComponent}>
            {reports.map((report, i) => {
              return i === 6 ? (
                <SingleReportType
                  key={"srt" + i}
                  id={report.id}
                  description={report.name}
                  handleChange={handleChange}
                  selectedValue={selectedValue}
                  hasGroup={false}
                />
              ) : i !== 0 && i !== 3 ? (
                <SingleReportType
                  key={"srt" + i}
                  id={report.id}
                  description={report.name}
                  handleChange={handleChange}
                  selectedValue={selectedValue}
                  hasGroup={true}
                />
              ) : i === 0 ? (
                <div key={"conteinerLessonInsights" + i}>
                  <h2 className={styles.GroupingHeader}>Lesson Insights</h2>
                  <SingleReportType
                    key={"srt" + i}
                    id={report.id}
                    description={report.name}
                    handleChange={handleChange}
                    selectedValue={selectedValue}
                    hasGroup={true}
                  />
                </div>
              ) : (
                selectedValue.length > 0 && (
                  <div key={"conteinerAverageCorrectness" + i}>
                    <h2 className={styles.GroupingHeader}>
                      Average Correctness
                    </h2>
                    <SingleReportType
                      key={"srt" + i}
                      id={report.id}
                      description={report.name}
                      handleChange={handleChange}
                      selectedValue={selectedValue}
                      hasGroup={true}
                    />
                  </div>
                )
              );
            })}
          </div>
        )
      ) : (
        <div key={"loading"}>
          <div style={{ height: "180px" }}></div>
          <div className={styles.TitleLoadingComponent}>
            <div>Fetching report types...</div>
          </div>
          <CircularProgress
            style={{ color: "#e9d5ff", width: "50px", height: "50px" }}
          />
        </div>
      )}
    </>
  );
};

export default AllReportsComponent;
